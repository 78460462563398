<template>
  <section class="bando-head">
    <h1 v-if="filtre == 'a-venir'">Rendez-vous à venir</h1>
    <h1 v-if="filtre == 'effectues'">Rendez-vous effectués</h1>
    <h1 v-if="filtre == 'annules'">Rendez-vous annulés</h1>
    <h1 v-if="filtre == 'tous'">Liste des rendez-vous</h1>
    <h1 v-if="!filtre || !filtre.length">Liste des rendez-vous</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">Rechercher un rendez-vous</h2>

      <!-- SEARCH -->
      <div class="bloc-search bloc-shadow">
        <form>
          <div class="row">
            <div class="col-md-9">
              <div class="form-group row">
                <label for="beneficiaire" class="col col-12 col-md-3">
                  Bénéficiaire
                </label>
                <div class="col col-12 col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    name="beneficiaire"
                    id="beneficiaire"
                    placeholder="Ex : Martine PICARD"
                  />
                </div>
              </div>
              <!-- <div class="form-group row">
                <label for="periode-start" class="col col-12 col-md-3">
                  Période du
                </label>
                <div class="col col-5 col-md-4">
                  <input
                    type="text"
                    class="form-control"
                    name="periode-start"
                    id="periode-start"
                    placeholder="Ex : 01/03/2021"
                  />
                </div>
                <label for="periode-end" class="col col-2 col-md-1 text-center">
                  au
                </label>
                <div class="col col-5 col-md-4">
                  <input
                    type="text"
                    class="form-control"
                    name="periode-end"
                    id="periode-end"
                    placeholder="Ex : 01/06/2021"
                  />
                </div>
              </div> -->
              <div class="form-group row">
                <label for="reference" class="col col-12 col-md-3">
                  Référence bénéficiaire
                </label>
                <div class="col col-12 col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    name="reference"
                    id="reference"
                    placeholder="Ex : 045658912-2"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="lieu" class="col col-12 col-md-3">
                  Lieu du rendez-vous
                </label>
                <div class="col col-12 col-md-9">
                  <input
                    type="text"
                    class="form-control"
                    name="lieu"
                    id="lieu"
                    placeholder="Ex : TOURS, 37, 37100"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label for="fse" class="col col-12 col-md-3">
                  FSE
                </label>
                <div class="col col-12 col-md-9">
                  <select class="form-control" name="fse" id="fse">
                    <option value=""></option>
                    <option
                      v-for="fse in fses"
                      :value="'FSE-' + fse.id"
                      :key="fse.id"
                    >
                      {{ fse.prenom }} {{ fse.nom }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-3 text-right">
              <button type="submit" class="btn btn-primary">Rechercher</button>
            </div> -->
          </div>
        </form>
      </div>

      <!-- TABLE -->
      <table
        id="table-rdv"
        class="table table-striped bloc-shadow dt-responsive"
      >
        <thead>
          <tr>
            <th></th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Code Postal et Commune</th>
            <th>FSE</th>
            <th>Rendez-vous</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>

      <!-- BTN EXPORT -->
      <div class="text-center">
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="exportCsv"
          v-if="isAdmin"
        >
          Exporter les rendez-vous
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { mapGetters } from "vuex";
import axios from "axios";
import { DateTime, Settings } from "luxon";

Settings.defaultLocale = "fr";

const $ = window.$;

export default {
  name: "RdvListe",
  components: {},
  data() {
    return {
      rdvs: [],
      filtre: "",
      dataTable: {},
      fses: [],
      error: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      "getProfile",
      "isProfileLoaded",
      "isAdmin",
      "isFse",
      "isOperateur",
      "isResponsable"
    ])
  },
  created() {
    const route = useRoute();
    if (route.params.filtre) {
      this.filtre = route.params.filtre;
    }
    axios
      .get(process.env.VUE_APP_API + "/fse/list")
      .then(resp => {
        this.fses = resp.data;
      })
      .catch(() => {
        this.$swal.fire("Une erreur s'est produite.");
      });
  },
  methods: {
    exportCsv: function() {
      axios
        .post(process.env.VUE_APP_API + "/rdv/export")
        .then(resp => {
          const blob = new Blob([resp.data], {
            type: "text/csv"
          });

          this.saveFile(blob, "25022021.csv");
        })
        .catch(() => {
          this.$swal.fire("Une erreur s'est produite.");
        });
    },
    saveFile(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log(from);
    this.filtre = to.params.filtre ? to.params.filtre : "";
    this.dataTable.ajax.reload();
    next();
  },
  mounted() {
    this.dataTable = $("#table-rdv").DataTable({
      dom: "<<t>r<lip>>",
      columnDefs: [
        {
          orderable: false,
          targets: 0
        }
      ],
      ajax: {
        url: process.env.VUE_APP_API + "/rdv/list",
        dataSrc: json => {
          json = json.filter(rdv => {
            if (this.filtre == "a-venir") {
              return (
                rdv.etat == "O" &&
                DateTime.fromISO(rdv.date_start) > DateTime.now()
              );
            } else if (this.filtre == "effectues") {
              return (
                rdv.etat == "O" &&
                DateTime.fromISO(rdv.date_start) < DateTime.now()
              );
            } else if (this.filtre == "annules") {
              return rdv.etat == "C";
            }
            return true;
          });
          this.rdvs = json;
          return json;
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token")
        }
      },
      processing: true,
      serverSide: false,
      columns: [
        {
          data: "beneficiaire",
          render: data =>
            '<a href="/edition-beneficiaire" class="edit-placeholder" id="' +
            data.uuid +
            '"><i class="fal fa-eye" aria-hidden="true"></i></a>'
        },
        {
          data: "beneficiaire",
          className: "text-uppercase",
          render: data => (data ? data.nom : "")
        },
        {
          data: "beneficiaire",
          render: data => (data ? data.prenom : "")
        },
        {
          data: "beneficiaire",
          className: "text-uppercase",
          render: data => (data && data.adresse ? data.adresse : "")
        },
        {
          data: "fse",
          render: data => data.prenom + " " + data.nom,
        },
        {
          data: "date_start",
          render: (data, type, row) => {
            console.log("type", type);
            if (row.etat === "C") {
              return "ANNULÉ";
            }
            if (data) {
              // const date = new Date(data);

              if (DateTime.fromISO(data) < DateTime.now()) {
                return "TERMINE";
              }
              // const options = {
              //   year: "numeric",
              //   month: "long",
              //   day: "2-digit",
              //   hour: "2-digit",
              //   minute: "2-digit"
              // };
              return DateTime.fromISO(data).toLocaleString(DateTime.DATETIME_MED);
            }
            return "";
          }
        },
        {
          data: "beneficiaire.reference",
          searchable: true,
          visible: false
        },
        {
          data: "fse.id",
          render: data => (data ? "FSE-" + data : ""),
          searchable: true,
          visible: false
        },
        {
          data: "date_start",
          render: (data, type, row) => {
            console.log("type", type);
            if (row.etat === "C") {
              return "B";
            }
            if (data) {
              const date = new Date(data);

              if (date < new Date()) {
                return "A";
              }
              const options = {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit"
              };
              return date.toISOString("fr-FR", options);
            }
            return "";
          },
          searchable: true,
          visible: false
        },
      ],
      order: [[8, "asc"]],
      pageLength: 10,
      language: {
        lengthMenu: "Par page : _MENU_",
        zeroRecords: "Aucun résultat",
        processing: "Chargement en cours...",
        paginate: {
          first: "Premier",
          last: "Dernier",
          next: "Suivant",
          previous: "Précédent"
        }
      },
      responsive: true
    });
    $(".dataTables_length select").addClass("custom-select");
    $("#beneficiaire").keyup(() => {
      this.dataTable.search($("#beneficiaire").val()).draw();
    });
    $("#reference").keyup(() => {
      this.dataTable.search($("#reference").val()).draw();
    });
    $("#lieu").keyup(() => {
      this.dataTable.search($("#lieu").val()).draw();
    });
    $("#fse").change(() => {
      this.dataTable.search($("#fse").val()).draw();
    });

    const self = this;
    $("tbody", this.$refs.table).on("click", ".edit-placeholder", function(e) {
      e.preventDefault();
      self.$router.push("/edition-beneficiaire/" + $(this).attr("id"));
    });
  }
};
</script>

<style scoped lang="scss">
.content {
  .bloc-search {
    .form-group {
      margin-bottom: 2rem;
    }
  }
}
</style>
